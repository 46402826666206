import React from 'react';
import ImageNew from '../../assets/images/3new.jfif';
import Image5 from '../../assets/images/5.jpeg';
import Image6 from '../../assets/images/6.jpeg';
import Image11 from '../../assets/images/11.jpeg';
import Image1 from '../../assets/images/1.jpeg';
import './style.css';
import MyMapComponent from "../../components/map/map";


const AboutSection = () => {
    return (
        <div className="md:px-8 md:py-8 w-full bg-[#F5F5F5]">
            <div className="flex flex-col md:flex-row justify-between items-center w-full my-8">
                <div className="w-full md:w-1/2 p-4 corner-border mx-2">
                    <h3 className="mb-4 text-4xl leading-tight ">O UrbanFyzio</h3>
                    <p className="text-lg leading-relaxed">
                        Vítejte v UrbanFyzio, kde klademe důraz na
                        individuální přístup ke každému klientovi. Pomůžeme vám zbavit se bolesti,
                        zlepšit funkci pohybového aparátu a vrátit se k aktivitám, které máte rádi. Díky našim odborným
                        znalostem vám poskytneme péči na nejvyšší úrovni.

                    </p>
                    <h3 className="mb-4 mt-6 text-4xl leading-tight">Co nabízíme?</h3>
                    <p className="text-lg leading-relaxed">
                        Naše fyzioterapeutická ambulance nabízí široké spektrum služeb zaměřených na vaše potřeby.
                        Ať už hledáte úlevu od chronické bolesti, rehabilitaci po úrazu nebo zlepšení sportovního
                        výkonu, máme řešení přesně pro vás.
                    </p>
                </div>
                <div className="w-full md:w-1/2 p-4 transition-transform transform">
                    <img src={ImageNew} alt="About Image"
                         className="w-3/4 h-3/4 object-cover rounded-md shadow-lg hover:shadow-xl"/>
                </div>
            </div>


            <div className="w-full md:w-fit p-4">
                <div className="grid grid-cols-2 gap-4">
                    <img src={Image5} alt="Gallery image 1" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image6} alt="Gallery image 2" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image11} alt="Gallery image 3" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image1} alt="Gallery image 4" className="w-full object-cover rounded-md shadow-lg"/>
                </div>
            </div>


            <div className="text-center mt-12 mb-8">
                <h3 className="mb-4 text-4xl font-semibold leading-tight">Máme volné termíny!</h3>
                <p className="text-lg leading-relaxed max-w-3xl mx-auto">
                    Přijďte se přesvědčit o naší kvalitě!
                    <br/>
                    <a href="https://urbanfyzio.reservio.com/"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="underline font-semibold text-[#0199ff]">Rezervujte se zde</a>
                    <br/>
                    nebo volejte
                    <br/>
                    <a href="tel:+420734312843" className="hover:text-gray-400 font-bold">+420 734 312 843</a>
                    <br/>
                    Najdete nás na adrese:
                    <br/>
                    <p className="font-bold">Palackého 131, Frýdek-Místek</p>
                </p>
            </div>
            <MyMapComponent center={[49.67464065551758, 18.343393325805664]} zoom={25} />


        </div>
    )
        ;
};

export default AboutSection;
