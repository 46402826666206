import React from 'react';
import backgroundImage from "../assets/test5.jpg";
import ImageNew from "../assets/images/2new (2).jfif";
import Image2 from "../assets/images/2.jpeg";
import Image3 from "../assets/images/7.jpeg";
import Image4 from "../assets/images/9.jpeg";
import ImageNew2 from "../assets/images/2new (1).jfif";
import Image6 from "../assets/images/3.jpeg";


const About = () => {
    return (

        <div className="flex flex-col h-full bg-[#F5F5F5]">
            <div className="relative bg-bottom bg-cover h-[30vh] flex items-center justify-center md:bg-center"
                 style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <h1 className="z-10 text-white text-6xl font-bold tracking-wider">O mně</h1>
            </div>


            <div className="flex flex-col items-center py-8">
                <div className="max-w-4xl w-full px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-8">
                        {/* Text Content */}
                        <div className="md:w-1/2">
                            <div className="p-4 corner-border mx-2">
                            <h3 className="mb-4 text-4xl leading-tight">Mgr. Šimon Urban</h3>
                            <p className="text-lg leading-relaxed mb-6">
                                Jsem absolventem magisterského studia na Lékařské fakultě Ostravské univerzity.
                                V rámci fyzioterapie se specializuji na běžnou populaci i vrcholové sportovce. V terapii se zaměřuji především na měkké a mobilizační techniky, které kombinuji s cvičením založeným na principech Dynamické neuromuskulární stabilizace (DNS).
                                Mým cílem je nejen zlepšení vašeho zdraví a pohybového aparátu, ale také prevence zranění a optimalizace výkonu.
                            </p>

                            </div>
                        </div>

                        {/* Image */}
                        <div className="md:w-1/2 flex justify-center">
                            <img src={ImageNew} alt="About Image" className="object-cover rounded-md shadow-lg hover:shadow-xl" style={{ maxWidth: '75%', maxHeight: '75%' }}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 py-8">
                <div className="flex flex-wrap justify-center gap-4">
                    <div className="w-full sm:w-1/2 lg:w-1/3">
                        <img src={Image2} alt="Gallery Image 1" className="w-full h-full object-cover rounded-md shadow-lg hover:shadow-xl"/>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/3">
                        <img src={Image4} alt="Gallery Image 2" className="w-full h-full object-cover rounded-md shadow-lg hover:shadow-xl"/>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/3">
                        <img src={Image3} alt="Gallery Image 3" className="w-full h-full object-cover rounded-md shadow-lg hover:shadow-xl"/>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/3">
                        <img src={ImageNew2} alt="Gallery Image 3" className="w-full h-full object-cover rounded-md shadow-lg hover:shadow-xl"/>
                    </div>
                </div>
            </div>

        </div>


    );
};

export default About;