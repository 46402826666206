import React from 'react';
import backgroundImage from "../assets/test5.jpg";

const Sluzby = () => {
    return (
        <div className="flex flex-col h-full bg-[#F5F5F5]">
            <div className="relative bg-bottom bg-cover h-[30vh] flex items-center justify-center md:bg-center"
                 style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <h1 className="z-10 text-white text-6xl font-bold tracking-wider">Naše služby</h1>
            </div>

            <div className="max-w-5xl mx-auto py-10 px-5 space-y-8">
                {/* Akutní potíže */}
                <div className="space-y-4">
                    <h2 className="text-3xl font-bold">Akutní potíže</h2>
                    <div className="h-[2px] bg-gray-300 w-1/2"></div>
                    <p className="text-lg text-gray-700">
                        Popis akutních potíží a jak fyzioterapie může pomoci.
                    </p>
                </div>

                {/* Chronické potíže */}
                <div className="space-y-4">
                    <h2 className="text-3xl font-bold">Chronické potíže</h2>
                    <div className="h-[2px] bg-gray-300 w-1/2"></div>
                    <p className="text-lg text-gray-700">
                        Popis chronických potíží a jak fyzioterapie může pomoci.
                    </p>
                </div>

                {/* Sportovní terapie */}
                <div className="space-y-4">
                    <h2 className="text-3xl font-bold">Sportovní terapie</h2>
                    <div className="h-[2px] bg-gray-300 w-1/2"></div>
                    <p className="text-lg text-gray-700">
                        Popis sportovní terapie a jak může pomoci sportovcům.
                    </p>
                </div>

                {/* Kineziotaping */}
                <div className="space-y-4">
                    <h2 className="text-3xl font-bold">Kineziotaping</h2>
                    <div className="h-[2px] bg-gray-300 w-1/2"></div>
                    <p className="text-lg text-gray-700">
                        Popis sportovní terapie a jak může pomoci sportovcům.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sluzby;
