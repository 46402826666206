import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/cs'; // import Czech locale
dayjs.locale('cs'); // use Czech locale

const Confirmation = ({ reservationData, totalPrice }) => {
    const date = reservationData.date.format('D. MMMM YYYY');
    const startTime = reservationData.time.format('HH:mm');
    const endTime = reservationData.time.add(1, 'hour').format('HH:mm');

    return (
        <div className="p-6 border border-gray-300 rounded-lg shadow-lg bg-white relative overflow-hidden max-w-md mx-auto">
            {/* Perforated edges */}
            <div className="absolute top-0 left-0 w-full border-t-2 border-dashed border-gray-300"></div>
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-dashed border-gray-300"></div>

            <h1 className="text-2xl font-bold mb-4 text-blue-600">Vaše schůzka</h1>
            <div className="mb-2 flex items-center text-gray-700">
                <strong>Služba:</strong> Individuální terapie
            </div>
            <div className="mb-2 flex items-center text-gray-700">
                <strong>Datum: </strong> {date}
            </div>
            <div className="mb-2 flex items-center text-gray-700">
                <strong>Čas:</strong> {startTime} - {endTime}
            </div>
            <p className="mt-4 text-xl font-bold border-t pt-4 text-blue-600">Celkem: 700 Kč</p>
        </div>
    );
}

export default Confirmation;
