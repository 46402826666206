import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/home/home';
import About from './pages/about';
import Header from './components/header/header';
import Footer from './components/footer/footer'
import Reservation from "./pages/reservation/reservation";
import Sluzby from "./pages/sluzby";
import Cenik from "./pages/cenik";
import Stripe from "./components/header/stripe";
import ScrollToTop from "./ScrollToTop";


const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Stripe/>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/sluzby" element={<Sluzby/>}/>
                <Route path="/cenik" element={<Cenik/>}/>
                <Route path="/o-mne" element={<About/>}/>
                <Route path="/rezervace" element={<Reservation/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
};

export default App;
