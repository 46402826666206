const Stripe = () => {
    return (
        <div className="bg-[#0199ff] text-white text-sm font-bold py-1 text-center flex justify-center items-center space-x-4">

            <a
                href="mailto:info@urbanfyzio.cz"
                className="hover:underline hidden md:inline"
                aria-label="Send us an email at info@urbanfyzio.cz"
            >
                info@urbanfyzio.cz
            </a>

            <a
                href="tel:+420 734 312 843"
                className="hover:underline"
                aria-label="Call us at +420 734 312 843"
            >
                +420 734 312 843
            </a>

            <a
                href="https://urbanfyzio.reservio.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
                aria-label="Book an appointment"
            >
                Rezervovat termín
            </a>
        </div>
    );
};

export default Stripe;
