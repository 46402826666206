import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, DateCalendar} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/cs';
import CustomTimePicker from './CustomTimePicker';

export default function DateTimePicker({ reservationData, setReservationData }) {
    const maxDate = dayjs().add(2, 'month');
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedDate, setSelectedDate] = useState(reservationData.date || dayjs());

    useEffect(() => {
        // Fetch available times from the backend
        // For this example, I'll just use some dummy data
        const times = ['08:00', '09:00', '11:00', '12:00', '13:00',  '16:00', '17:00'];
        setAvailableTimes(times);
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setReservationData(prevData => ({
            ...prevData,
            date: date
        }));
    };

    const handleTimeChange = (time) => {
        setReservationData(prevData => ({
            ...prevData,
            time: time
        }));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('cs')}>
            <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="md:w-1/2">
                    <DateCalendar
                        label="Vyberte datum"
                        value={selectedDate}
                        onChange={handleDateChange}
                        maxDate={maxDate}
                        disablePast={true}
                        format={"DD.MM.YYYY"}
                    />
                </div>
                <div className="md:w-1/2">
                    <CustomTimePicker
                        availableTimes={availableTimes}
                        selectedTime={reservationData.time}
                        onTimeSelect={handleTimeChange}
                        selectedDate={selectedDate}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
}
