import React from 'react';
import backgroundImage from '../assets/test4.jpg';

const Cenik = () => {
    const services = [
        { name: "Vstupní kineziologický rozbor 1h", price: "1000 CZK", link: "https://urbanfyzio.reservio.com/booking?step=2&serviceId=bedadea0-0722-4797-94aa-c2962455e8a1&resourceId=anyone-available" },
        { name: "Individuální fyzioterapie - 55 min", price: "700 CZK", link: "https://urbanfyzio.reservio.com/booking?step=2&serviceId=5240099f-9f44-4d73-a74d-02862e2cc972&resourceId=anyone-available" },
        { name: "Sportovní masáž - 45 min", price: "700 CZK", link: "https://urbanfyzio.reservio.com/booking?step=2&serviceId=ed85b79b-3cdf-419a-8098-22ba4c690668&resourceId=anyone-available" },
        { name: "Relaxační masáž - 45 min", price: "700 CZK", link: "https://urbanfyzio.reservio.com/booking?step=2&serviceId=1757217e-4242-4839-9644-ea05089dcb45&resourceId=anyone-available" },
    ];

    return (
        <div className="flex flex-col h-full bg-[#F5F5F5]">
            <div className="relative bg-center bg-cover h-[30vh] flex items-center justify-center"
                 style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <h1 className="z-10 text-white text-6xl font-bold tracking-wider">Ceník</h1>
            </div>

            <div className="max-w-3xl mx-auto my-0 md:my-10 bg-white p-6 rounded-lg shadow-xl transition-shadow hover:shadow-2xl flex-grow">
                <ul>
                    {services.map((service, index) => (
                        <li key={index} className="flex justify-between items-center border-b py-3 hover:bg-gray-50 transition-colors">
                            <span className="text-lg mr-4">{service.name}</span>
                            <span className="text-lg font-bold">{service.price}</span>
                            <a
                                href={service.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                                Rezervovat
                            </a>
                        </li>
                    ))}
                </ul>
                <p className="mt-6 text-red-500 text-sm">* Nepřijímáme platební karty.</p>
                <p className="mt-3 text-gray-600 text-sm">V případě nezrušení rezervace včas je účtována plná cena služby.</p>
                <p className="mt-3 text-gray-600 text-sm">Nemáme smlouvy se zdravotními pojišťovnami.</p>
            </div>

        </div>
    );
};

export default Cenik;
