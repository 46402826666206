import React from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

const CustomTimePicker = ({ availableTimes, selectedTime, onTimeSelect, selectedDate }) => {
    const selectedTimeString = selectedTime ? selectedTime.format('HH:mm') : '';
    const dayName = selectedDate ? dayjs(selectedDate).locale('cs').format('dddd') : '';

    return (
        <div className="time-picker p-4 border border-gray-300 rounded-lg">
            <div className="mb-4 text-lg font-bold">
                {selectedDate ? `${selectedDate.format('DD.MM.YYYY')} (${dayName})` : 'Vyberte datum'}
            </div>
            {availableTimes.map((time, index) => (
                <button
                    key={index}
                    className={`time-box w-16 h-16 rounded border border-gray-300 text-center mb-2 mr-2 ${selectedTimeString === time ? 'bg-blue-500 text-white' : ''}`}
                    onClick={() => onTimeSelect(dayjs(time, 'HH:mm'))}
                >
                    {time}
                </button>
            ))}
        </div>
    );
};

export default CustomTimePicker;
