import React from 'react';
import backgroundImage from '../../assets/landing2.jpg';
import LogoPNG from '../../assets/Logo 01.png';
import AboutSection from "./aboutSection";

const Home = () => {
    return (
        <div className="flex flex-col items-center w-full min-h-screen bg-[#F5F5F5]">

            {/* Background Image */}
            <div
                className="relative bg-cover bg-center bg-no-repeat h-[65vh] w-full"
                style={{backgroundImage: `url(${backgroundImage})`}}
            >
                {/* Overlay content on the image */}
                <div
                    className="absolute inset-0 flex flex-col justify-center items-center h-full bg-black bg-opacity-30 backdrop-blur-xs">
                    <div className="text-center">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white leading-tight mb-2 tracking-wide text-shadow">
                            Fyzioterapie Frýdek-Místek
                        </h1>
                        <h2 className="text-xl md:text-2xl font-light text-white mb-4 text-shadow">
                            Soukromá fyzioterapeutická ambulance UrbanFyzio
                        </h2>
                    </div>
                    <a
                        href="https://urbanfyzio.reservio.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-5 py-2.5 text-white font-medium rounded-lg bg-[#0199ff] hover:bg-[#0188e5] focus:outline-none focus:ring-2 focus:ring-[#0199ff] focus:ring-opacity-50"
                    >
                        Rezervace termínu
                    </a>
                </div>
            </div>

            {/* SVG Logo */}
            <div className="flex justify-center items-center mt-4 bg-[#F5F5F5]">
                <img src={LogoPNG} alt="Company Logo" className="w-[25vw] md:w-[15vw] lg:w-[12vw] xl:w-[10vw]"/>
            </div>

            {/* About Us Section */}
            <div className="px-2 py-2 bg-[#F5F5F5] w-4/5 lg:w-3/4">
                <AboutSection/>
            </div>

        </div>
    );
};

export default Home;
