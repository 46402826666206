import React, { useState, useEffect } from 'react';
import DateTimePicker from "./components/dateTimePicker"
import Form from "./components/form"
import HorizontalLinearStepper from "./components/stepper";
import Confirmation from "./components/confirmation";
import dayjs from 'dayjs';

const Reservation = () => {
    const [reservationData, setReservationData] = useState({
        date: dayjs(),
        time: null,
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    useEffect(() => {
        // Fetch available times for the default date from the backend
        // For this example, I'll just use some dummy data
        const times = ['08:00', '09:00', '11:00', '12:00', '13:00',  '16:00', '17:00'];
        const defaultTime = times[0]; // set the first available time as default
        setReservationData(prevData => ({
            ...prevData,
            time: dayjs(defaultTime, 'HH:mm')
        }));
    }, []);

    return (
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 max-w-6xl mx-auto mt-12 p-6">
            {/* Reservation Process */}
            <div className="w-full lg:w-3/4">
                <div className="min-h-[500px] p-6 border border-gray-300 rounded-lg shadow-md">
                    <HorizontalLinearStepper reservationData={reservationData}>
                        <DateTimePicker reservationData={reservationData} setReservationData={setReservationData} />
                        <Form reservationData={reservationData} setReservationData={setReservationData} />
                        <Confirmation reservationData={reservationData} />
                    </HorizontalLinearStepper>
                </div>
            </div>

            {/* Confirmation Component */}
            <div className="w-full lg:w-1/4">
                {reservationData.time && <Confirmation reservationData={reservationData} />}
            </div>
        </div>
    );

};

export default Reservation;
