import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Correcting the icon path if it doesn't show correctly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MyMapComponent = ({ center = [51.505, -0.09], zoom = 13 }) => {
    const mapRef = useRef(null); // This ref will hold the map instance
    const mapContainerRef = useRef(null); // This ref will hold the DOM element for the map container

    useEffect(() => {
        // Only initialize the map if it hasn't been initialized yet
        if (!mapRef.current) {
            mapRef.current = L.map(mapContainerRef.current, {
                center,
                zoom,
            });
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(mapRef.current);

            L.marker(center).addTo(mapRef.current)
                .bindPopup('UrbanFyzio');
        }

        // Return a cleanup function that will be called when the component unmounts
        return () => {
            if (mapRef.current) {
                mapRef.current.remove(); // This properly cleans up the map instance
                mapRef.current = null; // Ensures the map can be re-initialized if the component is used again
            }
        };
    }, [center, zoom]); // Rerun this effect if center or zoom changes

    return <div
        ref={mapContainerRef}
        id="map"
        style={{
            height: '500px',
            width: '100%',
            position: 'relative',
            zIndex: 1   // Ensure this is lower than the header's z-index
        }}
    />;
};

export default MyMapComponent;
