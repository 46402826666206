import {Link} from 'react-router-dom';
import email from '../../assets/email.svg';
import LogoPNG from '../../assets/Logo 02.png';
import Teams from './teams'; // Adjust the path according to your file structure


export default function Footer() {
    return (
        <footer className="w-full bg-[#262928]">
            <Teams/>
            <div className="p-6">

                <div className="mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-3 gap-6 items-center">

                    {/* Logo on the Left for medium screens */}
                    <div className="mb-6 text-center md:text-left">
                        <img src={LogoPNG} alt="Fitkomplex Logo" className="mx-auto w-32 h-auto mb-4 md:mb-0"/>
                    </div>

                    {/* Contact Information */}
                    <div className="mb-6 text-gray-300">
                        <h2 className="text-xl font-semibold mb-3">Kontaktujte nás</h2>
                        <div className="flex items-center mb-2">
                            <a href="mailto:johndoe@example.com" className="flex items-center hover:text-gray-400">
                                <img src={email} alt="Email Icon" className="mr-2 w-6 h-6"/>
                                <span className="text-xl font-bold">info@urbanfyzio.cz</span>
                            </a>
                        </div>
                        <div className="flex items-center mb-2">
                            <a href="tel:+420734312843" className="hover:text-gray-400">+420 734 312 843</a>
                        </div>
                        <div className="flex items-center">
                            <span>Palackého 131, Frýdek-Místek</span>
                        </div>
                    </div>

                    {/* Links */}
                    <div className="flex flex-col text-sm font-medium text-gray-400">
                        <Link className="hover:text-gray-300 mb-2" to="/cenik">Ceník</Link>
                        <Link className="hover:text-gray-300 mb-2" to="/o-mne">O mně</Link>
                    </div>


                </div>

                {/* Divider Line */}
                <hr className="my-4 border-t border-gray-700"/>

                {/* Copyright */}
                <div className="text-center py-2">
                    <span
                        className="text-sm text-gray-400 italic font-medium">© 2024 Urban Fyzio. All rights reserved.</span>
                </div>
            </div>
        </footer>
    );
}
