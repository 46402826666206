import React from 'react';

const Form = ({ reservationData, setReservationData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReservationData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <form>
            <div className="mb-5">
                <label htmlFor="name" className="block text-gray-700 mb-2" >Jméno a příjmení</label>
                <input type="text" id="name" name="name" placeholder="Jan Novák" required className="w-full p-3 border border-gray-300 rounded" value={reservationData.name} onChange={handleChange} />
            </div>

            <div className="mb-5">
                <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
                <input type="email" id="email" name="email" placeholder="jan.novak@test.cz" required className="w-full p-3 border border-gray-300 rounded" value={reservationData.email} onChange={handleChange} />
            </div>

            <div className="mb-5">
                <label htmlFor="phone" className="block text-gray-700 mb-2">Telefon</label>
                <input type="tel" id="phone" name="phone" placeholder="777 666 888" required className="w-full p-3 border border-gray-300 rounded" value={reservationData.phone} onChange={handleChange} />
            </div>

            <div className="mb-5">
                <label htmlFor="message" className="block text-gray-700 mb-2">Krátký popis problému</label>
                <textarea id="message" name="message" rows="4" placeholder="S čím mám problém..." className="w-full p-3 border border-gray-300 rounded" value={reservationData.message} onChange={handleChange}></textarea>
            </div>
        </form>
    );
}

export default Form;
