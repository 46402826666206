import React, {useState, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Logo from '../../assets/Logo For Website 01.png';
import {Divide as Hamburger} from 'hamburger-react'


const Header = () => {
    const location = useLocation();
    const [isOpen, setOpen] = useState(false);


    const menuItems = useMemo(() => [
        { to: "/cenik", text: "Ceník", isExternal: false },
        { to: "/o-mne", text: "O mně", isExternal: false },
        { to: "https://urbanfyzio.reservio.com/", text: "Rezervace", isExternal: true },
    ], []);


    const closeMenu = () => {
        setOpen(false);
    };

    const NavLink = ({ to, text, isExternal }) => {
        if (isExternal) {
            return (
                <a
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#333333] hover:bg-[#0199ff] hover:text-white px-4 py-2 rounded-md text-base font-semibold flex items-center transition-colors duration-200"
                >
                    {text}
                </a>
            );
        }

        const isActive = location.pathname.startsWith(to) ? "bg-[#0199ff] text-white" : "text-[#333333] hover:bg-[#0199ff] hover:text-white";
        return (
            <Link
                to={to}
                className={`${isActive} px-4 py-2 rounded-md text-base font-semibold flex items-center transition-colors duration-200`}
            >
                {text}
            </Link>
        );
    }


    return (

        <nav className="bg-[#F5F5F5] sticky top-0 z-20 shadow-md">
            <div className="mx-auto max-w-6xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <Link to="/" onClick={closeMenu}>
                        <img src={Logo} alt="Company Logo" className="h-10 w-auto mr-4"/>
                    </Link>
                    <div className="flex items-center">
                        {/* Desktop menu items */}
                        <div className="hidden sm:flex sm:items-center sm:space-x-2">
                            {menuItems.map(item => (
                                <NavLink key={item.to} location={location} {...item} />
                            ))}
                        </div>

                        {/* Mobile hamburger menu */}
                        <div className="sm:hidden ml-4">
                            <Hamburger
                                toggled={isOpen}
                                toggle={setOpen}
                                aria-controls="mobile-menu"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className="sm:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                        {menuItems.map(item => (
                            item.isExternal ? (
                                <a
                                    key={item.text}
                                    href={item.to}
                                    onClick={closeMenu}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block px-4 py-2 rounded-md text-base font-semibold text-[#333333] hover:bg-[#0199ff] hover:text-white"
                                >
                                    {item.text}
                                </a>
                            ) : (
                                <Link
                                    key={item.to}
                                    to={item.to}
                                    onClick={closeMenu}
                                    className={`${
                                        location.pathname.startsWith(item.to) ? "bg-[#0199ff] text-white" : "text-[#333333] hover:bg-[#0199ff] hover:text-white"
                                    } block px-4 py-2 rounded-md text-base font-semibold`}
                                >
                                    {item.text}
                                </Link>
                            )
                        ))}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Header;
